.ant-collapse-item {
  padding-top: 70px; /* Adjust for navbar height */
  margin-top: -70px;
}

.ant-collapse-item:target {
  border: 2px solid pink;
}

.suggest:hover span {
    display:none
  }
  
  .suggest:hover::after {
    content:"Suggest";
  }

