.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: 0.75rem !important;
}

.bg-primary {
  background-color: $primary-color;
}

.text-primary {
  color: $primary-color;
}

.text-red {
  color: $red;
}

.text-center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.ant-input-search-with-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.people .ant-collapse-content {
  margin-left: 30px;
}

.ant-collapse-extra {
  margin-right: 30px;
}

.text-muted {
  color: rgba(0, 0, 0, 0.5);
}

div:target {
  border: 2px solid pink;
}

a[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.justify-content-end {
  justify-content: end;
  margin-right: 1rem;
}
