$primary-color: #1890ff !default;
$bg-color: #f0f2f5 !default;
$dark-bg-color: #001529 !default;
$purple: #381D5B !default;
$red: #9A3516 !default;
$dark-red: #580707 !default;

$yellow: #FFFAB5 !default;
$orange: #F5A623 !default;

// Basic
// @import "antd/dist/antd.css";
@import "components/normalize";

// // Fonts
// // @import "styles/font";
// // @import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@400&display=swap");

// // Custom
@import "components/utils";
@import "components/custom";