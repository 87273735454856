main {
  padding: 2rem;
}

.ant-layout-header {
  position: relative;
  .trigger {
    position: absolute;
    right: 1rem;
    top: 39%;
  }
}

aside {
  width: 300px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1.2rem;
  height: 100vh;
  overflow: hidden;
  &.ant-layout-sider-collapsed {
    padding: 0;
    flex: 0 !important;
    min-width: 0 !important;
  }
}

.full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 100%;
    width: 500px;
  }
  .fixed-right {
    position: fixed;
    bottom: 1rem;
    right: 1.2rem;
  }
}

@media (min-width: 768px) {
  .full-height {
    min-height: 100vh;
  }
}

.mark-user {
  color: blue;
  text-decoration: underline;
}

.anticon-mr {
  display: inline-block;
  margin-right: 0.75rem;
}

// zoom
#zmmtg-root {
  display: none;
  .btn-default {
    background-color: inherit !important;
    border-color: inherit !important;
  }
}

.meeting-info-container--right-side {
  .btn-default {
    background-color: inherit !important;
    border-color: inherit !important;
    color: #fff;
    outline: none;
  }
  .dropdown-menu {
    right: 0;
    left: inherit;
  }
}

.jitsi {
  min-height: 740px;
  min-height: 100vh;
  display: flex;
  .jitsi-container {
    width: calc(100% - 360px);
    iframe {
      min-height: 600px;
    }
  }
  .jitsi-sidebar {
    width: 360px;
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 767px) {
  aside {
    display: none;
  }
  .jitsi {
    flex-direction: column;
    .jitsi-container {
      width: 100%;
    }
    .jitsi-sidebar {
      width: 100%;
    }
  }
}

.ant-collapse-extra {
  button {
    margin-right: 0.5rem;
  }
}

.indent {
  padding-left: 0.75rem;
  margin-bottom: 1rem;
}

.preview {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
}

main {
  position: relative;
}
#message-btn {
  position: absolute;
  top: 1rem;
  right: 2rem;
}
#create-btn {
  position: absolute;
  top: 1rem;
  right: calc(3rem + 150px);
}
.message {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #efefef;
  margin-bottom: 0.5rem;
  position: relative;
  padding-right: 2rem;
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  #message-btn {
    right: 1rem;
  }
  #message-btn + .ant-col,
  #message-btn + .ant-row {
    margin-top: 3rem;
  }
  #create-btn {
    right: calc(2rem + 150px);
  }
}

textarea {
  margin-bottom: 0.5rem !important;
}

#pending {
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1500;
  background-color: #fff;
  text-align: center;
  padding: 2rem;
  margin: auto;
  pointer-events: none;
}

.circle {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 0.5rem;
  &.green {
    background-color: #0bff40;
    border: 1px solid #06d606;
  }
  &.gray {
    background-color: #b5b5b5;
  }
}

.ant-menu.disabled {
  cursor: not-allowed;
  .ant-menu-item {
    pointer-events: none;
    opacity: 0.5 !important;
  }
}

#notice {
  float: right;
  margin-right: 5rem !important;
  // pointer-events: none;
  color: #1990ff;
}

@media (min-width: 571px) {
  #notice {
    white-space: break-spaces;
    width: 30%;
    line-height: 1.2;
    display: flex !important;
    align-items: center;
    height: 66px;
  }
}

@media (max-width: 1150px) {
  li.ant-menu-item {
    margin-right: 10px !important;
    margin-left: 10px !important;
    .anticon {
      display: none !important;
    }
    .ant-menu-title-content {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 570px) {
  #notice {
    white-space: nowrap;
  }
  li.ant-menu-item {
    .anticon {
      display: inline-block !important;
    }
    .ant-menu-title-content {
      margin-left: 10px !important;
    }
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.username {
  border-bottom: 1px solid #c3c3c3;
}

.heart {
  animation: heartbeat 1s infinite;
}

.ant-layout-header {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
}

.ant-layout-sider {
  position: sticky;
  top: 0;
}

.mobile-alert {
  padding: 1rem;
}

@media (min-width: 767px) {
  .mobile-alert {
    display: none;
  }
}

@keyframes blink {
  0% {
    background-color: rgba(0, 255, 217, 1)
  }
  50% {
      background-color: rgba(0, 255, 217, 0.5)
  }
  100% {
      background-color: rgba(0, 255, 217, 1)
  }
}
@-webkit-keyframes blink {
  0% {
    background-color: rgba(0, 255, 217, 1)
  }
  50% {
      background-color: rgba(0, 255, 217, 0.5)
  }
  100% {
      background-color: rgba(0, 255, 217, 1)
  }
}

.edit-blink {
      border-radius: 5px;
      -moz-transition:all 0.5s ease-in-out;
      -webkit-transition:all 0.5s ease-in-out;
      -o-transition:all 0.5s ease-in-out;
      -ms-transition:all 0.5s ease-in-out;
      transition:all 0.5s ease-in-out;
      -moz-animation:blink normal 1.5s infinite ease-in-out;
      /* Firefox */
      -webkit-animation:blink normal 1.5s infinite ease-in-out;
      /* Webkit */
      -ms-animation:blink normal 1.5s infinite ease-in-out;
      /* IE */
      animation:blink normal 1.5s infinite ease-in-out;
      /* Opera */
  }